/**
 * isDataLoading
 *
 * Checks if any of the states passed as arguments is loading
 */
export function isAnyStateLoading(...stateObjects: any[]) {
  for (let i = 0; i < stateObjects.length; i++) {
    const stateObject = stateObjects[i];
    const stateIsLoading =
      !stateObject ||
      (stateObject && typeof stateObject.isLoading === 'undefined') ||
      stateObject.isLoading;
    if (stateIsLoading) {
      return true;
    }
  }
  return false;
}
