import commonDayJs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

commonDayJs.extend(isoWeek);
commonDayJs.extend(utc);
commonDayJs.extend(duration);

export const dayjs = commonDayJs;
