import { define } from '@rlean/core';

export type EmployeeShiftAssignmentMessagesType = {
  body: string;
  employeeId: number;
  employeeShiftAssignmentId: number;
  mobilePhone: string;
  guid: string;
  scheduleDate: string;
  shift: number;
};

const EmployeeShiftAssignmentMessages =
  define<EmployeeShiftAssignmentMessagesType>(
    'employeeShiftAssignmentMessages',
    {
      postURL: '/EmployeeShiftAssignmentMessages',
    }
  );

export default EmployeeShiftAssignmentMessages;
