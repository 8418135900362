import React, { memo, useEffect, useState } from 'react';
import { pages } from 'config';
import { useActivePage } from 'lib/hooks';
import { useGet, usePut, useSave, useGlobalState } from '@rlean/core';
import { getValue, hasValue, deepCopy } from '@rlean/utils';
import { EmployeeShiftAssignments } from 'lib/entities';
import { isAnyStateLoading } from 'lib/helpers/stateDataChecks';
import { parse } from 'querystring';
import { Card, Skeleton, Tabs, Row, Col, message } from 'antd';
import { navigate, useLocation } from '@reach/router';
import ScheduleDetail from './ScheduleDetail';
import ScheduleList from './ScheduleList';
import { dayjs } from 'lib/helpers/dayjs';
import SendToPhoneModal from './SendToPhoneModal';

const MY_SCHEDULE_PARTS = {
  LIST: 'LIST',
  DETAIL: 'DETAIL',
};

const Schedules = (props) => {
  const [{ userDescription, employeeShiftAssignments }] = useGlobalState();
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [modalVisible, setModalVisibility] = useState(false);
  const [weekStartDate, setWeekStartDate] = useState(
    dayjs().startOf('isoWeek').format('YYYY/MM/DD')
  );
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedScheduleForModal, setSelectedScheduleForModal] =
    useState(null);
  const [partVisible, setPartVisible] = useState(MY_SCHEDULE_PARTS.LIST);
  const [put] = usePut();
  const [save] = useSave();

  const location = useLocation();
  useActivePage(pages.MY_SCHEDULE);

  let employeeId = null;

  if (!dataIsLoading) {
    employeeId = getValue(userDescription, 'data.employeeId', null);
  }

  useGet({
    entity: EmployeeShiftAssignments,
    params: { employeeId, weekStartDate },
  });

  useEffect(() => {
    const dataIsLoading = isAnyStateLoading(userDescription);
    setDataIsLoading(dataIsLoading);
  }, [userDescription]);

  // Check if URL contains the schedule id as query parameter
  useEffect(() => {
    const { id } = parse(location.search.slice(1));
    if (id && !isAnyStateLoading(employeeShiftAssignments)) {
      // get schedule details
      const schedule = (employeeShiftAssignments.data || []).find(
        (shift) => shift.id.toString() === id
      );
      setSelectedSchedule(schedule);
      setPartVisible(MY_SCHEDULE_PARTS.DETAIL);
    } else {
      setSelectedSchedule(null);
      setPartVisible(MY_SCHEDULE_PARTS.LIST);
    }
  }, [location, dataIsLoading, employeeShiftAssignments]);

  const showModal = (item) => {
    setSelectedScheduleForModal(item);
    setModalVisibility(true);
  };

  const hideModal = () => {
    setModalVisibility(false);
  };

  const handleAcknowledgeButton = (schedule) => {
    message.destroy();

    const newSchedule = {
      id: schedule.id,
      confirmedAt: dayjs().utc().format(),
      employeeId: schedule.employeeId,
    };

    message.loading('Acknowledging...');

    put(
      {
        entity: EmployeeShiftAssignments,
        params: { scheduleId: schedule.id },
        body: newSchedule,
      },
      (_, error) => {
        message.destroy();
        if (error) {
          message.error(
            `Couldn't acknowledge the schedule, try again in a moment`
          );
        } else {
          message.success('Acknowledged!');

          const allSchedule = deepCopy(
            getValue(employeeShiftAssignments, 'data', [])
          );

          for (let i = 0; i < allSchedule.length; i++) {
            if (allSchedule[i].id === schedule.id) {
              allSchedule[i]['confirmedAt'] = newSchedule.confirmedAt;
              break;
            }
          }

          save({
            entity: EmployeeShiftAssignments,
            value: { ...employeeShiftAssignments, data: allSchedule },
          });

          setSelectedSchedule({ ...schedule, ...newSchedule });
        }
      }
    );
  };

  const handleTabChange = (key) => {
    let _weekStartDate = '';
    const now = dayjs();
    if (key === 'thisweek') {
      _weekStartDate = now.startOf('isoWeek').format('YYYY/MM/DD');
    } else {
      _weekStartDate = now
        .startOf('isoWeek')
        .add(1, 'week')
        .format('YYYY/MM/DD');
    }
    setSelectedSchedule(null);
    setWeekStartDate(_weekStartDate);
    navigate(pages.MY_SCHEDULE.path);
  };

  if (dataIsLoading) {
    return (
      <Card style={{ marginTop: 25 }} data-testid='my-schedule-skeleton'>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <Card className='page-card schedules' title={pages.MY_SCHEDULE.title}>
      <Tabs
        defaultActiveKey='1'
        type='card'
        size='small'
        onTabClick={handleTabChange}
      >
        <Tabs.TabPane tab='This week' key='thisweek' />
        <Tabs.TabPane tab='Next week' key='nextweek' />
      </Tabs>
      <Row gutter={24}>
        <Col
          xs={24}
          lg={12}
          xxl={9}
          className={`section ${
            partVisible === MY_SCHEDULE_PARTS.LIST ? 'visible' : ''
          }`}
        >
          <ScheduleList
            tabWeek={weekStartDate}
            selectedSchedule={selectedSchedule}
            showSendToPhone={showModal}
          />
        </Col>
        <Col
          xs={24}
          lg={12}
          xxl={15}
          className={`section ${
            partVisible === MY_SCHEDULE_PARTS.DETAIL ? 'visible' : ''
          }`}
        >
          {hasValue(employeeShiftAssignments, 'data') &&
          employeeShiftAssignments.data.length > 0 ? (
            <div data-testid='schedule-detail'>
              <ScheduleDetail
                schedule={selectedSchedule}
                showSendToPhone={showModal}
                acknowledge={handleAcknowledgeButton}
              />
            </div>
          ) : null}
        </Col>
      </Row>
      <SendToPhoneModal
        schedule={selectedScheduleForModal}
        visible={modalVisible}
        handleHideModal={hideModal}
      />
    </Card>
  );
};

export default memo(Schedules);
