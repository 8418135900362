import React, { memo, useCallback } from 'react';
import { Space, Table, Checkbox } from 'antd';
import { useGlobalState } from '@rlean/core';
import { getValue, deepCopy } from '@rlean/utils';
import * as entities from 'lib/entities';
import { UserShiftType } from 'lib/entities/UserShiftTypes';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ShiftType } from 'lib/entities/ShiftTypes';
import { ColumnsType } from 'antd/lib/table';

type PreferredShiftsProps = {
  userShiftTypes: Omit<UserShiftType, 'id'>[];
  onChange: (shiftTypeId: number) => (event: CheckboxChangeEvent) => void;
};

const PreferredShifts = ({
  userShiftTypes = [],
  onChange = (id: number) => (event: CheckboxChangeEvent) => {},
}: PreferredShiftsProps) => {
  const [{ shiftTypes }] = useGlobalState<typeof entities>();

  const returnsHandledCheckbox = (val: { checked: boolean; id: number }) => (
    <Checkbox
      checked={val.checked}
      onChange={onChange(val.id)}
      data-testid={`check-${val.id}`}
    />
  );

  // helper function to get the shit status value from the user's
  const getShiftValue = useCallback(
    (shiftTypeId: number) => {
      return {
        checked: !getValue(
          userShiftTypes.find(
            (shiftType) => shiftType.shiftTypeId === shiftTypeId
          ) || {},
          'inactiveInd',
          false
        ),
        id: shiftTypeId,
      };
    },
    [userShiftTypes]
  );

  // Columns
  const columns: ColumnsType<any> = [
    {
      title: 'Day',
      dataIndex: 'day',
      width: 200,
      align: 'left',
    },
    {
      title: 'Shift',
      children: [
        {
          title: '1st',
          width: 100,
          align: 'center',
          dataIndex: 'shift1',
          render: returnsHandledCheckbox,
        },
        {
          title: '2nd',
          width: 100,
          align: 'center',
          dataIndex: 'shift2',
          render: returnsHandledCheckbox,
        },
        {
          title: '3rd',
          width: 100,
          align: 'center',
          dataIndex: 'shift3',
          render: returnsHandledCheckbox,
        },
      ],
    },
  ];

  // Build the shift types
  const tableShiftTypes = Object.values(
    getValue<ShiftType[]>(shiftTypes, 'data', []).reduce<{
      [key: string]: any;
    }>((pV, cV) => {
      if (cV.weekdayName in pV) {
        const newValue = deepCopy(pV[cV.weekdayName]);
        newValue[`shift${cV.shift}`] = getShiftValue(cV.id);
        return { ...pV, [cV.weekdayName]: newValue };
      } else {
        // get the shits from the userShitTypes
        return {
          ...pV,
          [cV.weekdayName]: {
            key: `${cV.weekdayName}`,
            day: cV.weekdayName,
            shift1: cV.shift === 1 ? getShiftValue(cV.id) : {},
            shift2: cV.shift === 2 ? getShiftValue(cV.id) : {},
            shift3: cV.shift === 3 ? getShiftValue(cV.id) : {},
          },
        };
      }
    }, {})
  );

  return (
    <Space direction='vertical' size='small'>
      <h1>Preferred Shifts</h1>
      <p>
        Select your preferred shifts. You can select multiple shifts, your
        scheduler will select some of them based on your preferred schedule.
      </p>
      <Table
        className='shift-preference-table'
        columns={columns}
        dataSource={tableShiftTypes}
        bordered
        pagination={false}
      />
    </Space>
  );
};

export default memo(PreferredShifts);
