import React, { memo } from 'react';
import parse from 'html-react-parser';
import { useActivePage } from 'lib/hooks';
import { pages, strings } from 'config';
import { Card } from 'antd';
import { RouteComponentProps } from '@reach/router';

const TermsOfUse = (props: RouteComponentProps) => {
  useActivePage(pages.TERMS_OF_USE);

  return (
    <Card className='page-card' title={strings.termsOfUse.title}>
      {parse(strings.termsOfUse.body)}
    </Card>
  );
};

export default memo(TermsOfUse);
