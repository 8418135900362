import React, { memo } from 'react';
import { strings, pages } from 'config';
import { useActivePage } from 'lib/hooks';
import { Card } from 'antd';
import parse from 'html-react-parser';
import { RouteComponentProps } from '@reach/router';

const Home = (props: RouteComponentProps) => {
  useActivePage(pages.HOME);

  return (
    <Card className='page-card' title={strings.home.title}>
      {parse(strings.home.content)}
    </Card>
  );
};

export default memo(Home);
