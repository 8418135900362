import React, { memo, useState, useEffect } from 'react';
import { pages, strings } from 'config';
import { useActivePage } from 'lib/hooks';
import { Card, Button, Space, Skeleton, message } from 'antd';
import { useGlobalState, useGet, usePost, usePut, useSave } from '@rlean/core';
import { getValue, deepCopy } from '@rlean/utils';
import {
  ShiftTypes,
  UserAvailabilities,
  UserShiftTypes,
  WorkScheduleTypes,
} from 'lib/entities';
import { isAnyStateLoading } from 'lib/helpers/stateDataChecks';
import PreferredSchedule from './PreferredSchedule';
import PreferredShifts from './PreferredShifts';
import ExtraInformation from './ExtraInformation';
import { RouteComponentProps } from '@reach/router';
import * as entities from 'lib/entities';
import { ShiftType } from 'lib/entities/ShiftTypes';
import { UserShiftType } from 'lib/entities/UserShiftTypes';
import { UserAvailability } from 'lib/entities/UserAvailabilities';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

// The following two functions creates the basic data structure for the profile
// form to work. These are used only in the case the user is freshly new
// and doesn't have any data attached.

const buildUserShiftTypes = (
  shiftTypes: ShiftType[],
  employeeId: string
): Omit<UserShiftType, 'id'>[] => {
  return shiftTypes.map((shiftType) => ({
    employeeId,
    inactiveInd: true,
    modifiedAt: new Date().toISOString(),
    modifiedByEmployeeId: employeeId,
    shiftTypeId: shiftType.id,
  }));
};

const buildUserAvailabilities = (
  employeeId: string,
  id: number
): UserAvailability[] => {
  return [
    {
      id,
      canDrive: false,
      employeeId,
      modifiedAt: new Date().toISOString(),
      modifiedByEmployeeId: employeeId,
      hasPassport: false,
      holidays: false,
      inactiveInd: false,
      note: '',
      overnightTravel: false,
      willTravel: false,
      workScheduleTypeId: 2,
    },
  ];
};

const ShiftPreference = (props: RouteComponentProps) => {
  const [{ userDescription, userShiftTypes, userAvailabilities, shiftTypes }] =
    useGlobalState<typeof entities>();
  const [localUserAvailabilities, setLocalUserAvailabilities] = useState<
    UserAvailability[]
  >([]);
  const [localUserShiftTypes, setLocalUserShiftTypes] = useState<
    Omit<UserShiftType, 'id'>[]
  >([]);

  const [post] = usePost();
  const [put] = usePut();
  // const [save] = useSave();

  useActivePage(pages.SHIFT_PREFERENCE);

  const employeeId = getValue(userDescription, 'data.employeeId', null);
  useGet({ entity: UserAvailabilities, params: { employeeId } });
  useGet({ entity: UserShiftTypes, params: { employeeId } });

  useGet({ entity: WorkScheduleTypes });
  useGet({ entity: ShiftTypes });

  const dataIsLoading = isAnyStateLoading(
    userDescription,
    userShiftTypes,
    userAvailabilities,
    shiftTypes
  );

  // Get the shifts and availability preferences, and create the objects
  // from scratch if there's none.
  useEffect(() => {
    if (!dataIsLoading) {
      const employeeId = userDescription.data.employeeId;

      const newLocalUserShiftTypes = getValue(userShiftTypes, 'data', []);
      if (newLocalUserShiftTypes.length === 0) {
        setLocalUserShiftTypes(
          buildUserShiftTypes(shiftTypes.data, employeeId)
        );
      } else {
        setLocalUserShiftTypes(newLocalUserShiftTypes);
      }

      const newLocalUserAvailabilities = getValue(
        userAvailabilities,
        'data',
        []
      );
      if (newLocalUserAvailabilities.length === 0) {
        setLocalUserAvailabilities(
          buildUserAvailabilities(employeeId, userAvailabilities.data[0].id)
        );
      } else {
        setLocalUserAvailabilities(newLocalUserAvailabilities);
      }
    }
  }, [
    dataIsLoading,
    userShiftTypes,
    userAvailabilities,
    userDescription,
    shiftTypes,
  ]);

  const resetChanges = () => {
    setLocalUserShiftTypes(getValue(userShiftTypes, 'data', []));
    setLocalUserAvailabilities(getValue(userAvailabilities, 'data', []));
  };

  const handleAvailabilityChange = <K extends keyof UserAvailability>(
    key: K,
    value: UserAvailability[K]
  ) => {
    const newUserAvailability = deepCopy(localUserAvailabilities[0]);
    newUserAvailability[key] = value;
    setLocalUserAvailabilities([newUserAvailability]);
  };

  const handleUserShiftTypeChange =
    (shiftTypeId: number) =>
    ({ target: { checked } }: CheckboxChangeEvent) => {
      const newUserShifts = deepCopy(localUserShiftTypes);
      const shiftToBeUpdatedIndex = newUserShifts.findIndex(
        (uS) => uS.shiftTypeId === shiftTypeId
      );

      if (shiftToBeUpdatedIndex !== -1) {
        newUserShifts[shiftToBeUpdatedIndex].inactiveInd = !checked;
        newUserShifts[shiftToBeUpdatedIndex].modifiedByEmployeeId =
          employeeId ?? '';
        setLocalUserShiftTypes(newUserShifts);
      } else {
        console.error(
          "Shift couldn't be updated because the shift doesn't exists in the main object. Check if the userShiftTypes is being stored correctly"
        );
      }
    };

  const saveChanges = () => {
    message.destroy();
    message.loading('Saving your changes...');

    const saveUserAvailabilitiesPromise = new Promise((resolve, reject) => {
      put<
        UserAvailability,
        Omit<UserAvailability, 'id'>,
        typeof UserAvailabilities
      >(
        {
          entity: UserAvailabilities,
          params: { id: getValue(localUserAvailabilities[0], 'id') },
          body: {
            ...localUserAvailabilities[0],
            modifiedByEmployeeId: employeeId ?? '',
          },
        },
        (data, error) => {
          if (error) {
            reject();
          } else {
            // Data is already saved on put
            // save({
            //   entity: UserAvailabilities,
            //   value: {
            //     ...userAvailabilities,
            //     data: [
            //       {
            //         ...localUserAvailabilities[0],
            //         modifiedByEmployeeId: employeeId,
            //       },
            //     ],
            //   },
            // });
            resolve(null);
          }
        }
      );
    });

    const saveUserShiftTypesPromise = new Promise((resolve, reject) => {
      post(
        { entity: UserShiftTypes, body: localUserShiftTypes },
        (_, error) => {
          if (error) {
            reject();
          } else {
            // Data is already saved on post
            // save({
            //   entity: UserShiftTypes,
            //   value: { ...userShiftTypes, data: localUserShiftTypes },
            // });
            resolve(null);
          }
        }
      );
    });

    Promise.all([saveUserAvailabilitiesPromise, saveUserShiftTypesPromise])
      .then(() => {
        message.destroy();
        message.success('Your preferences have been saved!');
      })
      .catch(() => {
        message.destroy();
        message.error("Couldn't save your preferences");
      });
  };

  if (dataIsLoading) {
    return (
      <Card style={{ marginTop: 25 }}>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <>
      <p>{strings.shiftPreference.notice}</p>
      <Card className='page-card' title={pages.SHIFT_PREFERENCE.title}>
        <Space direction='vertical' size='large'>
          <PreferredSchedule
            userAvailabilities={localUserAvailabilities}
            onChange={handleAvailabilityChange}
          />
          <PreferredShifts
            userShiftTypes={localUserShiftTypes}
            onChange={handleUserShiftTypeChange}
          />
          <ExtraInformation
            userAvailabilities={localUserAvailabilities}
            onChange={handleAvailabilityChange}
          />
          <Space>
            <Button type='default' onClick={resetChanges}>
              Cancel
            </Button>
            <Button type='primary' onClick={saveChanges}>
              Save
            </Button>
          </Space>
        </Space>
      </Card>
    </>
  );
};

export default memo(ShiftPreference);
