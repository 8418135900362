import { define } from '@rlean/core';

export type EmployeePortalMessagesType = {
  acknowledgeInd: boolean;
  acknowledged: boolean;
  addedAt: Date;
  delivered: boolean;
  employeeId: number;
  id: number;
  inactiveInd: boolean;
  message: string;
  modifiedAt: Date;
  portalMessageId: number;
  priorityInd: boolean;
  senderEmail: string;
  senderFirstName: string;
  senderLastName: string;
  senderMobileUserId: number;
  solarEmployeeId: number;
  subject: string;
};

const EmployeePortalMessages = define<EmployeePortalMessagesType[]>(
  'employeePortalMessages',
  {
    nullableParams: false,
    getURL: '/EmployeePortalMessages',
    putURL: '/EmployeePortalMessages/:id',
  }
);

export default EmployeePortalMessages;
