import { dayjs } from 'lib/helpers/dayjs';
import { WorkHistoryDetail } from 'lib/entities/WorkHistoryDetails';

export type AggregatedTime = {
  total: number;
  records: { [key: string]: { data: WorkHistoryDetail[]; total: number } };
};

/**
 * This function sorts and reduces the workHistoryDetails
 * @todo The type of data should be matched with what the history details is
 */
const aggregateTime = (data: any[]): AggregatedTime => {
  // The work history details are groupped by date and the time is sumed / aggregated
  // totally and per day
  return data
    .sort((a: any, b: any) => {
      if (a.calendarDate === b.calendarDate) {
        return dayjs(a.startTime).isBefore(b.startTime) ? -1 : 1;
      } else {
        return dayjs(a.calendarDate).isBefore(b.calendarDate) ? -1 : 1;
      }
    })
    .reduce(
      (previousValue, currentValue) => {
        const key = currentValue.calendarDate;

        if (Object.keys(previousValue.records).includes(key)) {
          // If the date's data is already stored: update the main total, store this
          // current record and sum the day's total
          return {
            ...previousValue,
            total: previousValue.total + currentValue.duration_Minutes,
            records: {
              ...previousValue.records,
              [key]: {
                data: [...previousValue.records[key].data, currentValue],
                total:
                  previousValue.records[key].total +
                  currentValue.duration_Minutes,
              },
            },
          };
        } else {
          // In case this is a new date: update the main total and add the new date's
          // data with the day's total
          return {
            ...previousValue,
            total: previousValue.total + currentValue.duration_Minutes,
            records: {
              ...previousValue.records,
              [key]: {
                data: [currentValue],
                total: currentValue.duration_Minutes,
              },
            },
          };
        }
      },
      { total: 0, records: {} }
    );
};

export default aggregateTime;
