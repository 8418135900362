import { strings } from './strings';

export type Page = {
  key: string;
  title: string;
  path: string;
};

export const pages: { [key: string]: Page } = {
  LANDING_PAGE: {
    key: 'LANDING_PAGE',
    title: strings.home.title,
    path: '/',
  },
  SIGN_UP: {
    key: 'SIGN_UP',
    title: strings.signUp.title,
    path: '/sign-up',
  },
  TERMS_OF_USE: {
    key: 'TERMS_OF_USE',
    title: strings.termsOfUse.title,
    path: '/terms-of-use',
  },
  PRIVACY_POLICY: {
    key: 'PRIVACY_POLICY',
    title: strings.privacyPolicy.title,
    path: '/privacy-policy',
  },
  HOME: {
    key: 'HOME',
    title: strings.home.title,
    path: '/home',
  },
  MY_SCHEDULE: {
    key: 'MY_SCHEDULE',
    title: strings.mySchedule.title,
    path: '/my-schedule',
  },
  PROFILE: {
    key: 'PROFILE',
    title: strings.profile.title,
    path: '/profile',
  },
  SHIFT_PREFERENCE: {
    key: 'SHIFT_PREFERENCE',
    title: strings.shiftPreference.title,
    path: '/shift-preference',
  },
  WORK_HISTORY: {
    key: 'WORK_HISTORY',
    title: strings.workHistory.title,
    path: '/work-history',
  },
  NOTIFICATIONS: {
    key: 'NOTIFICATIONS',
    title: strings.notifications.title,
    path: '/notifications',
  },
  SUPPORT: {
    key: 'SUPPORT',
    title: strings.support.title,
    path: '/support',
  },
  UNEXPECTED_ERROR: {
    key: 'UNEXPECTED_ERROR',
    title: strings.unexpectedError.title,
    path: '/unexpected-error',
  },
};
