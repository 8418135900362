import React, { memo } from 'react';
import { Button, Descriptions, Empty, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { EmployeePortalMessagesType } from 'lib/entities/EmployeePortalMessages';

type NotificationDetailProps = {
  message?: EmployeePortalMessagesType;
  onDelete: Function;
  onAcknowledge: Function;
};

const NotificationDetail = ({
  message,
  onDelete,
  onAcknowledge,
}: NotificationDetailProps) => {
  if (!message) {
    return <Empty description='Select a message!' />;
  }

  const handleOnDelete = () => {
    if (onDelete) {
      onDelete(message.id);
    }
  };

  const handleOnAcknowledge = () => {
    if (onAcknowledge) {
      onAcknowledge(message.id);
    }
  };

  return (
    <Space direction='vertical'>
      <Descriptions bordered column={1} size='default' layout='vertical'>
        <Descriptions.Item label='Subject'>{message.subject}</Descriptions.Item>
        <Descriptions.Item label='Sent'>
          {message.addedAt.toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label='From'>
          {`${message.senderLastName}, ${message.senderFirstName} - ${message.senderEmail}`}
        </Descriptions.Item>
        <Descriptions.Item
          label='Message'
          labelStyle={{ verticalAlign: 'top' }}
          contentStyle={{ whiteSpace: 'pre-line' }}
        >{`${message.message}`}</Descriptions.Item>
      </Descriptions>
      <Space direction='horizontal'>
        <Button
          danger
          onClick={handleOnDelete}
          data-testid='notification-delete-btn'
        >
          Delete
        </Button>
        <Button type='primary' data-testid='notification-reply-btn'>
          <a
            href={`mailto:${message.senderEmail}`}
            style={{ textDecoration: 'none' }}
          >
            Reply
          </a>
        </Button>
        {message.acknowledgeInd && !message.acknowledged && (
          <Button
            icon={<CheckOutlined />}
            onClick={handleOnAcknowledge}
            data-testid='notification-acknowledge-btn'
          >
            Acknowledge
          </Button>
        )}
      </Space>
    </Space>
  );
};

export default memo(NotificationDetail);
