import React, { memo, useState } from 'react';
import { useGlobalState, useGet } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { WorkHistoryDetails } from 'lib/entities';
import { dayjs } from 'lib/helpers/dayjs';
import { isAnyStateLoading } from 'lib/helpers/stateDataChecks';
import { Card, Empty, Skeleton, Space } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useActivePage } from 'lib/hooks';
import aggregateTime from 'lib/helpers/aggregateTime';
import { pages } from 'config';
import DatePicker from 'components/shared/DatePicker';
import WorkHistoryReportPanel from './WorkHistoryReportPanel';
import { strings } from 'config';
import { RouteComponentProps } from '@reach/router';
import * as entities from 'lib/entities';

const WorkHistory = (props: RouteComponentProps) => {
  const [{ workHistoryDetails, userDescription }] =
    useGlobalState<typeof entities>();
  const [weekStartDate, setWeekStartDate] = useState(
    dayjs().startOf('isoWeek').format('YYYY/MM/DD')
  );
  useActivePage(pages.WORK_HISTORY);

  const dataIsLoading = isAnyStateLoading(workHistoryDetails, userDescription);

  const employeeId = getValue(userDescription, 'data.employeeId', null);
  useGet({
    entity: WorkHistoryDetails,
    params: { employeeId, weekStartDate },
  });

  const dateChange = (value: any) => {
    setWeekStartDate(dayjs(value).startOf('isoWeek').format('YYYY-MM-DD'));
  };

  const aggregatedTime = aggregateTime(workHistoryDetails?.data || []);

  const totalTimeInHours = dayjs
    .duration({ minutes: aggregatedTime.total })
    .asHours();

  const EmptyPanel = (
    <Empty
      description={
        <>
          <h1>{`${strings.workHistory.empty.title} ${weekStartDate}`}</h1>
          <p>{strings.workHistory.empty.description}</p>
        </>
      }
    />
  );

  if (dataIsLoading) {
    return (
      <Card style={{ marginTop: 25 }}>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <Card className='page-card' title={pages.WORK_HISTORY.title}>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Space>
          {strings.workHistory.pickerWeekOf}
          <DatePicker
            format='YYYY-MM-DD'
            value={dayjs(weekStartDate)}
            onChange={dateChange}
          />
        </Space>
        <Space>
          {strings.workHistory.workedTotalTime}
          <ClockCircleOutlined />
          {totalTimeInHours.toFixed(2)}
          {strings.workHistory.hours}
        </Space>
        {(workHistoryDetails?.data || []).length > 0 ? (
          <WorkHistoryReportPanel aggregatedTime={aggregatedTime} />
        ) : (
          EmptyPanel
        )}
      </Space>
    </Card>
  );
};

export default memo(WorkHistory);
