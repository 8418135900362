import React, { memo } from 'react';
import parse from 'html-react-parser';
import { useActivePage } from 'lib/hooks';
import { pages, strings } from 'config';
import { Card } from 'antd';
import { RouteComponentProps } from '@reach/router';

const PrivacyPolicy = (props: RouteComponentProps) => {
  useActivePage(pages.PRIVACY_POLICY);

  return (
    <Card className='page-card' title={strings.privacyPolicy.title}>
      {parse(strings.privacyPolicy.body)}
    </Card>
  );
};

export default memo(PrivacyPolicy);
