import React from 'react';
import { Card, Col, Typography, Row, Space } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { dayjs } from 'lib/helpers/dayjs';
import { AggregatedTime } from 'lib/helpers/aggregateTime';
import { WorkHistoryDetail } from 'lib/entities/WorkHistoryDetails';

const { Text } = Typography;

type WorkHistoryReportPanelProps = {
  aggregatedTime: AggregatedTime;
};

const WorkHistoryReportPanel = ({
  aggregatedTime,
}: WorkHistoryReportPanelProps) => {
  const recordPanelItem = (record: WorkHistoryDetail) => {
    const recordTime = dayjs
      .duration({ minutes: record.duration_Minutes })
      .asHours();
    const startTime = dayjs(record.startTime).format('HH:mm');
    const endTime = dayjs(record.endTime).format('HH:mm');
    return (
      <Row
        className='work-record'
        key={`record-date-${record.id}`}
        data-testid='record-date-item'
      >
        <Col sm={8} xs={24}>
          <Space>
            <ClockCircleOutlined />
            {`${startTime} - ${endTime}`}
          </Space>
        </Col>
        <Col sm={8} xs={12}>
          {record.activity}
        </Col>
        <Col sm={8} xs={12}>
          <Space>
            <ClockCircleOutlined className='hide-on-mobile' />
            {recordTime.toFixed(2)} hours
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <Card title='Report' className='work-history-report'>
      {Object.keys(aggregatedTime.records).map((key) => {
        const records = aggregatedTime.records[key].data;
        const total = dayjs
          .duration({ minutes: aggregatedTime.records[key].total })
          .asHours();
        return (
          <Row
            className='work-history-date-item'
            key={`records-${records[0].calendarDate}`}
          >
            <Col md={4} xs={24} className='date-header'>
              <Text className='weekday'>{records[0].weekdayName}</Text>
              <Text type='secondary' className='date'>
                {dayjs(records[0].calendarDate).format('MM/DD/YYYY')}
              </Text>
              <span className='hours'>{`${total} hours`}</span>
            </Col>
            <Col md={20} xs={24} className='date-body'>
              {records.map((record) => recordPanelItem(record))}
            </Col>
          </Row>
        );
      })}
    </Card>
  );
};

export default WorkHistoryReportPanel;
