import { define } from '@rlean/core';

export type EmployeeShiftAssignment = {
  assignmentStatus: string;
  confirmedAt: Date;
  employeeId: number;
  firstName: string;
  id: number;
  lastName: string;
  modifiedBySchedulerMobileUserId: number;
  scheduleDate: string;
  schedulerEmail: string;
  schedulerName: string;
  schedulerPhone: string;
  shift: number;
  shiftEndTime: Date;
  shiftName: string;
  shiftStartTime: Date;
  shiftSupervisorEmail: string;
  shiftSupervisorMobilePhone: string;
  shiftSupervisorName: string;
  solarEmployeeId: number;
  weekStartDate: Date;
  weekdayName: string;
  workSiteCity: string;
  workSiteCompanyId: number;
  workSiteLatitude: number;
  workSiteLongitude: number;
  workSiteName: string;
  workSitePostalCode: string;
  workSiteStateProv: string;
  workSiteStreetAddress: string;
};

const EmployeeShiftAssignments = define<EmployeeShiftAssignment[]>(
  'employeeShiftAssignments',
  {
    nullableParams: false,
    getURL: '/EmployeeShiftAssignments',
    putURL: '/EmployeeShiftAssignments/:scheduleId',
  }
);

export default EmployeeShiftAssignments;
