import React, { memo } from 'react';
import { strings } from 'config';
import { msalInstance } from 'config/msal';

const Unauthorized = () => {
  const handleSignOut = () => {
    msalInstance.logoutRedirect().then(() => {
      window.location.reload();
    });
  };
  return (
    <>
      <div>Logo</div>
      <div>You are not authorized to access this application.</div>
      <div>
        It seems that the account that you are using is does not have the
        required permissions to access this application. If you think that you
        should be able to access this page and you are seeing this message in
        error, please contact itrequests@stratospherequality.com
      </div>
      <div>
        If you have an employee number and you want to create an account please
        click the button below to create an account.
      </div>
      <button onClick={() => handleSignOut()}>{strings.actions.signOut}</button>
    </>
  );
};

export default memo(Unauthorized);
