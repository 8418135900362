import { define } from '@rlean/core';

type WorkScheduleType = {
  id: number;
  name: string;
  modifiedAt: Date;
  inactiveInd: boolean;
};

const WorkScheduleTypes = define<WorkScheduleType[]>('workScheduleTypes', {
  initialState: [],
  getURL: '/WorkScheduleTypes',
});

export default WorkScheduleTypes;
