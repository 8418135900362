import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { pages, strings } from 'config';
import { useActivePage } from 'lib/hooks';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
} from 'antd';
import { useGlobalState, useGet, usePut, useSave } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { Countries, EmployeeProfiles, StateProvinces } from 'lib/entities';
import addressGeocoder from './address_geocoder';
import { isAnyStateLoading } from 'lib/helpers/stateDataChecks';
import { RouteComponentProps } from '@reach/router';
import * as entities from 'lib/entities';
import { EmployeeProfileType } from 'lib/entities/EmployeeProfiles';

const Profile = (props: RouteComponentProps) => {
  const formMountRef = useRef(null);
  const [{ countries, stateProvinces, userDescription, employeeProfiles }] =
    useGlobalState<typeof entities>();
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState(-1);
  const [saving, setSaving] = useState(false);
  const [put] = usePut();
  const [save] = useSave();

  useActivePage(pages.PROFILE);

  const dataIsLoading = isAnyStateLoading(
    userDescription,
    countries,
    stateProvinces,
    employeeProfiles
  );

  const getProfileValue = useCallback(
    <K extends keyof EmployeeProfileType>(key: K) => {
      if (!dataIsLoading && employeeProfiles.data?.length > 0) {
        return employeeProfiles.data[0][key];
      }
      return -1;
    },
    [dataIsLoading, employeeProfiles]
  );

  useEffect(() => {
    let isMounted = true;
    const formMount = formMountRef.current;
    if (!dataIsLoading && isMounted) {
      setSelectedCountry(getProfileValue('countryId'));
    }
    return () => {
      isMounted = false;
      if (formMount) {
        form.resetFields();
      }
    };
  }, [dataIsLoading, form, getProfileValue]);

  const employeeId = userDescription?.data?.employeeId ?? null;
  useGet({ entity: EmployeeProfiles, params: { employeeId } });
  useGet({ entity: Countries });
  useGet({ entity: StateProvinces });

  const updateCountryId = (changedFields: any) => {
    if (
      changedFields.length > 0 &&
      getValue<string[]>(changedFields[0], 'name', []).includes('countryId')
    ) {
      setSelectedCountry(changedFields[0].value);
      form.setFieldsValue({ stateProvinceId: '' });
    }
  };

  const resetChanges = () => {
    if (formMountRef.current) {
      form.resetFields();
    }
  };

  const saveChanges = async (values: any) => {
    setSaving(true);
    message.destroy();
    message.loading('Saving profile changes...');

    const state = stateProvinces.data.find(
      (state) => state.id === values.stateProvinceId
    );
    const country = countries.data.find(
      (country) => country.id === values.countryId
    );

    const geocode = await addressGeocoder(
      `${values.address1} ${values.address2}`,
      values.city,
      state?.name || '',
      values.postalCode,
      country?.name || ''
    );

    const body = {
      ...values,
      modifiedByEmployeeId: employeeId,
      employeeId: getProfileValue('employeeId'),
      id: getProfileValue('id'),
      latitude: geocode.latitude,
      longitude: geocode.longitude,
    };

    put(
      { entity: EmployeeProfiles, params: { id: getProfileValue('id') }, body },
      (_, error) => {
        message.destroy();
        if (error) {
          message.error('There has been an error, try again later.');
        } else {
          if (!geocode.valid) {
            message.warning(
              `Your data have been saved, but your address couldn't be validated. Please, check if the address is correct.`,
              4
            );
          } else {
            message.success('Profile updated!');
          }
          save({
            entity: EmployeeProfiles,
            value: {
              ...employeeProfiles,
              data: [{ ...employeeProfiles.data[0], ...body }],
            },
          });
        }
        setSaving(false);
      }
    );
  };

  if (dataIsLoading) {
    return (
      <Card style={{ marginTop: 25 }} data-testid='profile-skeleton'>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <Card className='page-card' title={strings.profile.title}>
      {getProfileValue('modifiedAt') ? (
        <p>
          Last updated by {getProfileValue('modifiedByEmployeeDisplayName')} at{' '}
          {new Date(getProfileValue('modifiedAt')).toLocaleString()}
        </p>
      ) : null}
      <Form
        ref={formMountRef}
        layout='vertical'
        form={form}
        onFinish={saveChanges}
        onFieldsChange={(changedFields) => {
          updateCountryId(changedFields);
        }}
        initialValues={{
          address1: getProfileValue('address1'),
          address2: getProfileValue('address2'),
          countryId: getProfileValue('countryId'),
          city: getProfileValue('city'),
          stateProvinceId: getProfileValue('stateProvinceId'),
          postalCode: getProfileValue('postalCode'),
          mobilePhone: getProfileValue('mobilePhone'),
          allowText: getProfileValue('allowText'),
          homePhone: getProfileValue('homePhone'),
          alternateEmail: getProfileValue('alternateEmail'),
        }}
      >
        <Form.Item name='address1' label='Home Address'>
          <Input />
        </Form.Item>
        <Form.Item name='address2' label='Apt # Unit'>
          <Input />
        </Form.Item>
        <Form.Item name='countryId' label='Country' style={{ width: '100%' }}>
          <Select
            showSearch
            optionFilterProp='children'
            placeholder='Select a country'
          >
            {(countries.data ?? []).map((country) => (
              <Select.Option
                value={country.id}
                key={`country-sel-${country.id}`}
              >
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={24}>
          <Col md={9} xs={24}>
            <Form.Item name='city' label='City'>
              <Input />
            </Form.Item>
          </Col>

          <Col md={9} xs={24}>
            <Form.Item name='stateProvinceId' label='State/Province'>
              <Select
                showSearch
                optionFilterProp='children'
                placeholder='Select a state'
              >
                {(stateProvinces.data ?? [])
                  .filter(
                    (stateProvince) =>
                      stateProvince.countryId === selectedCountry
                  )
                  .map((stateProvince) => (
                    <Select.Option
                      value={stateProvince.id}
                      key={`state-prov-sel-${stateProvince.id}`}
                    >
                      {stateProvince.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={6} xs={24}>
            <Form.Item name='postalCode' label='Zip/Postal Code'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={18}>
            <Form.Item name='mobilePhone' label='Mobile Phone'>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item
              name='allowText'
              label='Accept Text?'
              valuePropName='checked'
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Form.Item name='homePhone' label='Alternative Phone'>
              <Input />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item name='alternativeEmail' label='Alternative Email'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button
              onClick={resetChanges}
              disabled={saving}
              data-testid='reset'
            >
              Cancel
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              disabled={saving}
              data-testid='submit'
            >
              Update
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default memo(Profile);
