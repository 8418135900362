import React, { FC, memo, useEffect, useState } from 'react';
import { Card } from 'antd';
import parse from 'html-react-parser';
import { RouteComponentProps, useLocation } from '@reach/router';
import { pages, strings } from 'config';

const StaticViewer: FC<RouteComponentProps> = () => {
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (location.pathname === `/static${pages.TERMS_OF_USE.path}`) {
      setTitle(pages.TERMS_OF_USE.title);
      setContent(strings.termsOfUse.body);
    }
    if (location.pathname === `/static${pages.PRIVACY_POLICY.path}`) {
      setTitle(pages.PRIVACY_POLICY.title);
      setContent(strings.privacyPolicy.body);
    }
  }, [location]);

  return (
    <Card title={title} className='page-card'>
      {parse(content)}
    </Card>
  );
};

export default memo(StaticViewer);
