import React, { memo } from 'react';
import { Spin } from 'antd';

const LoadingSpin = () => {
  return (
    <div className='loading-container'>
      <div className='loading-content'>
        <Spin size='large' tip='Loading...' />
      </div>
    </div>
  );
};

export default memo(LoadingSpin);
