import React, { memo } from 'react';
import { Layout } from 'antd';
import AppBar from './AppBar';
import MenuLayout from './Menu/MenuLayout';

const Main = ({ children }: { children: React.ReactNode }) => {
  const { Sider, Header, Content } = Layout;
  return (
    <Layout>
      <Sider className='main-sider' breakpoint='xl' collapsedWidth='0'>
        <MenuLayout />
      </Sider>
      <Layout className='main-section'>
        <Header className='main-header'>
          <AppBar />
        </Header>
        <Content className='main-content'>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default memo(Main);
