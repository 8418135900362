import { define } from '@rlean/core';

export type UserAvailability = {
  canDrive: boolean;
  employeeId: string;
  hasPassport: boolean;
  holidays: boolean;
  id: number;
  inactiveInd: boolean;
  modifiedAt: string; // Date
  modifiedByEmployeeId: string;
  note: string;
  overnightTravel: boolean;
  willTravel: boolean;
  workScheduleTypeId: number;
};

const UserAvailabilities = define<UserAvailability[]>('userAvailabilities', {
  initialState: [],
  nullableParams: false,
  getURL: '/EmployeeAvailabilities',
  putURL: '/EmployeeAvailabilities/:id',
});

export default UserAvailabilities;
