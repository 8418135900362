import React, { memo, useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Menu, Drawer } from 'antd';
import { navigate, Link, RouteComponentProps } from '@reach/router';
import {
  MenuOutlined,
  SolutionOutlined,
  UnlockOutlined,
  ClockCircleOutlined,
  TwitterOutlined,
  FacebookFilled,
  LinkedinFilled,
} from '@ant-design/icons';
import logoWhite from 'assets/SQ-logo-white.png';
import logoColor from 'assets/SQ-logo-color.png';
import { pages } from 'config/pages';
import { strings } from 'config';

import PWAPrompt from 'react-ios-pwa-prompt';
import { useIsAuthenticated } from '@azure/msal-react';
import { msalInstance } from 'config/msal';

const PWAPromptAsAny = PWAPrompt as any;

const LandingPage: React.FC<RouteComponentProps> = () => {
  const { Header, Content, Footer } = Layout;
  const [visible, setVisible] = useState(false);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home');
    }
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const handleSignIn = () => {
    msalInstance.loginRedirect();
  };

  const handleDrawerSupport = () => {
    navigate(
      'mailto:itrequests@stratospherequality.com?subject=Team Portal: General Support'
    );
    closeDrawer();
  };

  const handleGeneralSupport = (e: React.MouseEvent<HTMLElement>) => {
    navigate(
      'mailto:customerservice@stratospherequality.com?subject=Team Portal: Request New Account'
    );
    e.currentTarget.blur();
  };

  const handleRequestAccount = () => {
    navigate(pages.SIGN_UP.path);
  };

  const LandingSidebar = () => (
    <>
      <button
        className='link-button mobile-only'
        onClick={() => showDrawer()}
        type='button'
      >
        <MenuOutlined />
      </button>
      <Drawer
        className='main-sider landing-menu'
        placement='left'
        closable={false}
        onClose={() => closeDrawer()}
        visible={visible}
      >
        <Row justify='center'>
          <Col span={24}>
            <Link to='/'>
              <img src={logoColor} alt='Stratosphere Quality logo' />
            </Link>
          </Col>
        </Row>
        <Menu>
          <Menu.Item
            onClick={handleDrawerSupport}
            icon={<ClockCircleOutlined />}
          >
            {strings.actions.askForSupport}
          </Menu.Item>
          <Menu.Item onClick={handleRequestAccount} icon={<SolutionOutlined />}>
            {strings.actions.signUp}
          </Menu.Item>
          <Menu.Item onClick={handleSignIn} icon={<UnlockOutlined />}>
            {strings.actions.signIn}
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );

  return (
    <Layout className='landing-section'>
      <Header className='landing-header'>
        <Row className='landing-app-bar' justify='space-between'>
          <Col span={8} className='left'>
            <span className='desktop-only'>
              <strong>{strings.appName}</strong>
            </span>
            <span className='mobile-only'>
              <LandingSidebar />
            </span>
          </Col>
          <Col span={8} className='middle'>
            <Link to='/'>
              <img src={logoWhite} alt='Stratosphere Quality logo' />
            </Link>
          </Col>
          <Col span={8} className='right'>
            <Button
              className='desktop-only'
              type='primary'
              role='link'
              onClick={(e) => handleGeneralSupport(e)}
              style={{ margin: '0 10px' }}
            >
              {strings.actions.askForSupport}
            </Button>
            <Button
              className='desktop-only'
              type='primary'
              onClick={handleRequestAccount}
              style={{ margin: '0 10px' }}
            >
              {strings.actions.signUp}
            </Button>
            <Button
              className='desktop-only'
              type='primary'
              onClick={handleSignIn}
              style={{ margin: '0 10px' }}
            >
              {strings.actions.signIn}
            </Button>
          </Col>
        </Row>
      </Header>
      <Content className='landing-content'>
        <Row justify='center' align='middle' style={{ height: '100%' }}>
          <Col span={12} className='landing-call-to-action'>
            <h1 className='landing-message'>Stay In Touch</h1>
            <h2 style={{ color: 'white' }}>
              Interact with your colleagues at Stratosphere and stay informed
            </h2>
            <Button type='primary' onClick={handleRequestAccount} size='large'>
              Sign Up Now
            </Button>
          </Col>
        </Row>
      </Content>
      <Footer className='landing-footer'>
        <Row justify='space-between'>
          <Col span={24} xl={6} className='left'>
            <Row justify='space-around' className='landing-social-icons'>
              <Col span={6}>
                <a
                  href='https://www.facebook.com/Stratosphere-Quality-LLC-468960463193346/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='facebook-icon' />
                  <FacebookFilled />
                </a>
              </Col>
              <Col span={6}>
                <a
                  href='https://www.linkedin.com/company/stratosphere-quality'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='linkedin-icon' />
                  <LinkedinFilled />
                </a>
              </Col>
              <Col span={6}>
                <a
                  href='http://twitter.com/improveproducts'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <TwitterOutlined />
                </a>
              </Col>
            </Row>
            <Row justify='space-between' className='landing-terms'>
              <Col span={10}>
                <Link to={`/static${pages.TERMS_OF_USE.path}`}>
                  {pages.TERMS_OF_USE.title}
                </Link>
              </Col>
              <Col span={10}>
                <Link to={`/static${pages.PRIVACY_POLICY.path}`}>
                  {pages.PRIVACY_POLICY.title}
                </Link>
              </Col>
            </Row>
          </Col>
          <Col span={24} xl={18} className='right'>
            <p>
              © Stratosphere Quality {new Date().getFullYear()} all rights
              reserved.
            </p>
          </Col>
        </Row>
      </Footer>
      <PWAPromptAsAny
        copyBody='This website has app functionality. Use the safari browser to add it to your home screen.'
        copyShareButtonLabel='1) Press the "Share" button on the menu bar.'
      />
    </Layout>
  );
};

export default memo(LandingPage);
