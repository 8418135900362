import { define } from '@rlean/core';

type Country = {
  id: number;
  name: string;
  code: string;
  inactiveInd: boolean;
  modifiedAt: Date;
  addedAt: Date;
};

const Countries = define<Country[]>('countries', {
  initialState: [],
  getURL: '/Countries',
});

export default Countries;
