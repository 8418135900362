import React, { memo } from 'react';
import { Space, Radio } from 'antd';
import { useGlobalState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import * as entities from 'lib/entities';
import { UserAvailability } from 'lib/entities/UserAvailabilities';

type PreferredScheduleProps = {
  userAvailabilities: UserAvailability[];
  onChange: <K extends keyof UserAvailability>(
    key: K,
    value: UserAvailability[K]
  ) => void;
};

const PreferredSchedule = ({
  userAvailabilities = [],
  onChange = () => {},
}: PreferredScheduleProps) => {
  const [{ workScheduleTypes }] = useGlobalState<typeof entities>();

  // Build the Schedule preferences
  const schedulePreferences = getValue(workScheduleTypes, 'data', []).map(
    ({ id, name }) => ({
      label: name,
      value: id,
    })
  );

  const disabled = userAvailabilities.length === 0;

  return (
    <Space direction='vertical' size='small'>
      <h1>Preferred Schedule</h1>
      <p>
        Select your preferred type of schedule. You can only select only one
        type of schedule
      </p>
      <Radio.Group
        className='preferred-schedule-radio-group'
        value={disabled ? -1 : userAvailabilities[0].workScheduleTypeId}
        disabled={disabled}
        onChange={({ target: { value } }) =>
          onChange('workScheduleTypeId', value)
        }
        options={schedulePreferences}
        optionType='button'
      />
    </Space>
  );
};

export default memo(PreferredSchedule);
