import React, { memo, useState } from 'react';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import MenuLayout from './MenuLayout';

const SidebarMobile = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        className='mobile-only'
      >
        <button
          className='link-button'
          onClick={showDrawer}
          type='button'
          data-testid='drawer-toggle-button'
        >
          <MenuOutlined />
        </button>
      </div>
      <Drawer
        className='main-sider'
        placement='left'
        closable={false}
        onClose={onClose}
        visible={visible}
        data-testid='drawer'
      >
        <MenuLayout mobileNavClick={onClose} />
      </Drawer>
    </>
  );
};

export default memo(SidebarMobile);
