import React, { memo } from 'react';
import { Menu } from 'antd';
import {
  HomeFilled,
  ClockCircleOutlined,
  UserOutlined,
  CalendarOutlined,
  HistoryOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import { strings, pages } from 'config';
import { useGlobalState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { msalInstance } from 'config/msal';
import * as entities from 'lib/entities';

const MenuNavigation = ({ mobileNavClick = () => {} }) => {
  const [{ activePage }] = useGlobalState<typeof entities>();

  const handleLogout = () => {
    msalInstance.logoutRedirect().then(() => {
      window.location.reload();
    });
  };

  const menuProps = {
    selectedKeys:
      hasValue(activePage, 'data.key') &&
      typeof activePage.data.key !== 'undefined'
        ? [activePage.data.key]
        : [],
    onClick: () => {},
  };

  if (mobileNavClick) {
    menuProps.onClick = mobileNavClick;
  }

  return (
    <Menu {...menuProps} mode='inline'>
      <Menu.Item key={pages.HOME.key} icon={<HomeFilled />}>
        <Link to={pages.HOME.path}>{pages.HOME.title}</Link>
      </Menu.Item>
      <Menu.Item key={pages.MY_SCHEDULE.key} icon={<ClockCircleOutlined />}>
        <Link to={pages.MY_SCHEDULE.path}>{pages.MY_SCHEDULE.title}</Link>
      </Menu.Item>
      <Menu.Item key={pages.PROFILE.key} icon={<UserOutlined />}>
        <Link to={pages.PROFILE.path}>{pages.PROFILE.title}</Link>
      </Menu.Item>
      <Menu.Item key={pages.SHIFT_PREFERENCE.key} icon={<CalendarOutlined />}>
        <Link to={pages.SHIFT_PREFERENCE.path}>
          {pages.SHIFT_PREFERENCE.title}
        </Link>
      </Menu.Item>
      <Menu.Item key={pages.WORK_HISTORY.key} icon={<HistoryOutlined />}>
        <Link to={pages.WORK_HISTORY.path}>{pages.WORK_HISTORY.title}</Link>
      </Menu.Item>
      <Menu.Item key={pages.NOTIFICATIONS.key} icon={<MailOutlined />}>
        <Link to={pages.NOTIFICATIONS.path}>{pages.NOTIFICATIONS.title}</Link>
      </Menu.Item>
      <Menu.Item key={pages.SUPPORT.key} icon={<QuestionCircleOutlined />}>
        <Link to={pages.SUPPORT.path}>{pages.SUPPORT.title}</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item icon={<LogoutOutlined />}>
        <Link to='#' onClick={handleLogout}>
          {strings.actions.signOut}
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default memo(MenuNavigation);
