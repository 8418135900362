import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { RLean } from '@rlean/core';

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT}`,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
};

const scopes = [process.env.REACT_APP_MSAL_TOKEN_SCOPE || ''];

export const msalInstance = new PublicClientApplication(msalConfig);

const redirectOnError = (error: any) => {
  console.error("Couldn't get token, redirecting to login", { error });
  msalInstance.loginRedirect();
};

export const getToken = async () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    console.error('No active account!');
    return;
  }

  const response = await msalInstance
    .acquireTokenSilent({
      account,
      scopes,
    })
    .catch(redirectOnError);

  if (response) {
    RLean.setToken(response.accessToken);

    return response.accessToken;
  }

  return null;
};

export const msalApiFetch = (fetch: Function, url: string, options?: any) => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    console.error('No active account!');
    return null;
  }

  return msalInstance
    .acquireTokenSilent({ account, scopes })
    .then((response: any) => {
      const o = options || {};
      if (!o.headers) {
        o.headers = {};
      }
      o.headers.Authorization = `Bearer ${response.accessToken}`;

      return fetch(url, o);
    })
    .catch(redirectOnError);
};
