import { define } from '@rlean/core';

export type EmployeeProfileType = {
  address1: string;
  address2: string;
  address3: string;
  allowText: boolean;
  alternateEmail: string;
  city: string;
  countryId: number;
  employeeId: number;
  homePhone: string;
  id: number;
  inactiveInd: boolean;
  latitude: number;
  longitude: number;
  mobilePhone: string;
  modifiedAt: Date;
  modifiedByEmployeeDisplayName: string;
  modifiedByEmployeeFirstName: string;
  modifiedByEmployeeId: number;
  modifiedByEmployeeLastName: string;
  modifiedByEmployeeTitle: string;
  postalCode: string;
  stateProvinceId: number;
};

const EmployeeProfiles = define<EmployeeProfileType[]>('employeeProfiles', {
  getURL: '/EmployeeProfiles',
  putURL: '/EmployeeProfiles/:id',
  nullableParams: true,
});

export default EmployeeProfiles;
