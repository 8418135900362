import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    appName: 'YOUR TEAM PORTAL',
    home: {
      title: 'Home',
      content: `
      <h2>Welcome to your Team Portal</h2>
      <h3>What's New?</h3>
      <p>
        Notifications is a new feature that allows HR/Benefits and others to keep you up to date with key information,
        dates and notices.
      </p>
      <h3>What is it?</h3>
      <p>
        This portal is about you and us engaging to improve your experience working with Stratosphere. Here, you can:
      </p>
      <ul>
        <li>View and confirm your scheduled shift assignments</li>
        <li>Communicate with schedulers</li>
        <li>Track your work history</li>
        <li>Keep informed about key HR events and issues</li>
        <li>Provide us feedback to make things better</li>
      </ul>
      <p>
        Participation is critical. It is especially important for you to keep address and contact information up to date
        in your Profile and work day preferences up to date in your Shift Preference. Doing so makes it easier to
        schedule your assignments and accommodate your requests.
      </p>
      <h3>What's next?</h3>
      <p>
        We're looking at the new features to add in 2020 right now. Help us and your team mates by visiting the Support
        link, selecting Website feedback, and offering your suggestions.
      </p>
      `,
    },
    mySchedule: {
      title: 'My Schedule',
      action: {
        goBack: 'Go back',
        sendToPhone: 'Send to phone',
        acknowledge: 'Acknowledge',
        seeDetails: 'See details',
        cancel: 'Cancel',
        send: 'Send',
      },
      status: {
        acknowledged: 'Acknowledged',
        notAcknowledged: 'Please acknowledge this schedule.',
      },
      empty: {
        title: 'Shifts are not available for week of',
        description: `
        Even if no shifts are listed you could be scheduled to work.
        Shift schedules might not be firm several days in advance so
        continue to check at least daily. Please contact your
        scheduler or manager to confirm if you have questions.`,
      },
      modalDescription: 'A message will be sent to your phone number below',
    },
    profile: { title: 'Profile' },
    shiftPreference: {
      title: 'Shift Preference',
      notice:
        'Customer needs may override your shift preferences. See Preferences in the Terms and Conditions.',
    },
    workHistory: {
      title: 'Work History',
      empty: {
        title: 'No work history was found for the week of ',
        description: `
        Hours for the previous Monday to Sunday period are updated Wednesday
        at 6:15PM Eastern. The current week's hours will be updated next
        Wednesday. If you think time for this period is missing, please
        click on Support and send a support request. Indicate which week has
        the missing time in your request.
        `,
      },
      pickerWeekOf: 'Week of',
      workedTotalTime: 'Worked a total of',
      hours: 'hours',
    },
    notifications: { title: 'Notifications' },
    support: { title: 'Support' },
    signUp: {
      title: 'Sign Up',
      signingUp: 'Signing up...',
      errorMessage: {
        code302: 'This user account already exists.',
        code400: 'Bad Request if the Email format is invalid.',
        code404: 'Please check your employee number, not match found.',
        code409:
          'This email/employee number combination is already registered.',
        default: 'Something went wrong, please try again or contact support.',
      },
      signIn: 'Sign In',
      form: {
        subtitle1: `If you have a Stratosphere Quality email address or you've already signed up, please`,
        subtitle2: `here Otherwise, fill out the form to sign up.`,
        email: {
          validMessage: 'This input is not valid E-mail.',
          requiredMessage: 'Please input your E-mail.',
        },
        firstName: {
          requiredMessage: 'Please input your first name.',
        },
        lastName: {
          requiredMessage: 'Please input your last name.',
        },
        sqEmployeeNum: {
          requiredMessage: 'Please input your employee #.',
        },
        acceptAgreement: 'Should accept agreement.',
        buttonSignUp: 'Sign Up',
      },
      success: {
        title: "You're signed up!",
        instructions1: 'You will shortly receive an email to',
        instructions2: 'with a link to confirm your email address.',
        instructions3: 'After confirming your email, you can go to',
        instructions4:
          'If for some reason you do not receive an email please contact ',
      },
    },
    unexpectedError: { title: 'Unexpected Error' },
    termsOfUse: {
      title: 'Terms of Use',
      body: `    <p>
          Please read the following Terms of Use (“Terms”) carefully. They govern your use of Stratosphere Quality, LLC’s
          (“Stratosphere”) SQTeamwork.com website (the “Website”). By using the Website, you acknowledge that you have read
          and understood all the Terms, and you agree to be bound by these Terms. If you do not agree to these Terms, you
          may not use the Website. Authorized use of the Website is limited to active employees of Stratosphere and
          contractor employees assigned to a Stratosphere work location. Stratosphere reserves the right to change the Terms
          in its sole and absolute discretion at any time without notice. It is your responsibility to review these Terms
          for any changes each time that you use the Website. All changes to the Terms are effective from the date posted.
        </p>

        <h3>Nature of the Website</h3>
        <p>
          The Website is an online, interactive medium where employees may find information about their work schedules, past
          work history and other topics. Employees may interact Stratosphere scheduling and supervisory personnel as well as
          and view current work assignments. Employees may also use the Website to provide Stratosphere with current contact
          information and preferred work scheduling preferences that will be shared with Stratosphere schedulers,
          recruiters, human resources specialists and operations supervisors and managers to improve Stratosphere workforce
          assignment. You acknowledge and agree that you will provide accurate information through the Website. You further
          acknowledge and agree that the Website and the information you provide is not the sole, or primary, source of
          information Stratosphere will use to plan and determine your present or future work assignments. While
          Stratosphere will utilize such information you provide, all final work assignments will be executed in the best
          interest of Stratosphere and Stratosphere customers.
        </p>

        <h3>Registration</h3>
        <p>
          To access and use functions of the Website, you will be required to setup a user account (“Your Account”). In
          setting up and maintaining Your Account, you agree to provide accurate information regarding your identity, your
          contact information, and any other information requested by Stratosphere related to the Website. You will set your
          own password for accessing the Website and shall be solely and strictly liable for everything that occurs using
          Your Account. You are responsible for maintaining the confidentiality of your password and agree to accept
          responsibility for all activities that occur under Your Account or password. You agree to immediately notify
          Stratosphere of any unauthorized use of your password or Your Account, or any other breach of security of which
          you become aware.
        </p>

        <h3>Preferences</h3>
        <p>
          Submitting work and shift preferences does not guarantee that these will be the shifts you will work but your
          preferences will factor into how Stratosphere schedulers and supervisors plan your work assignments. Making a
          scheduling preference or availability change on this website does not replace the requirement for you to submit a
          time off request to your Stratosphere scheduler or supervisor. Failure to call in a proper time off request will
          result in an unapproved absence and potential disciplinary action.
        </p>

        <h3>Use of the Website</h3>
        <p>
          You may use the Website only for legal and appropriate uses. Stratosphere reserves the right to make changes to
          the Website at any time and without notice. Your access to and use of the Website is completely at the discretion
          of Stratosphere and your access to and use of the Website may be blocked, suspended, or terminated without prior
          notice at any time due to termination of employment or for any reason or for no reason, including, without
          limitation, for any violation of the following rules: You must be an active Stratosphere employee, or active
          employee of a staffing contractor. You may not modify, adapt, translate, or make derivative works of the Website.
          You may not attempt to gain unauthorized access to any function or feature of the Website, by hacking, password
          “mining” or any other illegitimate means. You must comply with all state, federal, and/or international laws,
          rules, policies and/or licenses governing communications while using the Website. You may not impersonate any
          other persons or otherwise misrepresent your identity or your affiliation with any person, group or entity when
          using the Website. You must provide truthful information to Stratosphere in your interactions using the Website.
        </p>

        <h3>License</h3>
        <p>
          Stratosphere grants you a limited license to access and make personal use of the Website. However, you may not
          download or modify the Website, or any portion of the Website, except with express written consent of
          Stratosphere. You may not sell, republish, frame in another webpage, or use on another website, any of the
          information, documentation, and other content, or any portion thereof, posted in or on the Website without the
          prior written consent of Stratosphere. You may view, print, copy, and download portions of the information,
          documentation, and other content of the Website solely in connection with your use of the Website, and solely for
          your own individual, internal, non-commercial use or records. Stratosphere reserves the right to revoke this
          authorization at any time. Any unauthorized use of the Website shall automatically terminate the license granted
          to you by Stratosphere for such use.
        </p>

        <h3>Third Party Information; Links to Other Sites</h3>
        <p>
          The Website may contain windows or hyperlinks which provide access to other websites not affiliated with the
          Website or with Stratosphere (the “External Websites”), or to informational content provided by the External
          Websites. Your use of such External Websites or informational content is subject to and governed by the terms and
          guidelines, if any, contained within such External Website. Stratosphere is not liable for and does not endorse
          nor make any representations or warranties concerning any informational content, products, services, software, or
          other materials available on such External Websites, even if a page or pages of the External Website is framed
          within a page of this Website. Stratosphere is not responsible for the privacy practices or the content of
          External Websites. For your protection, please refer to the terms of service and privacy policies of the
          respective External Websites. You acknowledge, understand, and agree that Stratosphere shall not be responsible or
          liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with
          your use of or reliance on any such informational content, products, services, software, or other materials
          available on or provided by any External Website.
        </p>

        <h3>Disclaimer of Warranties</h3>
        <p>
          You understand and expressly agree that use of the Website is at your sole risk. Stratosphere does not guarantee,
          represent, or warrant that your use of the Website will be uninterrupted, error free, or virus free, or that the
          Website will meet your requirements. In addition, no warranty is made as to the results that may be obtained from
          use of the Website, or as to the accuracy, reliability, or content of any product, service, or information
          provided through or in connection with the Website. Although Stratosphere uses reasonable security and encryption
          provisions, we do not guarantee and make no representations or warranties concerning our ability to prevent
          unauthorized access of or tampering with the Website or information accessible through the Website.
        </p>

        <p>
          TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE WEBSITE IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION, DATA, SERVICES, OR UNINTERRUPTED
          ACCESS. SPECIFICALLY, STRATOSPHERE DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO: (1) ANY
          WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, USEFULNESS, OR CONTENT OF INFORMATION, PRODUCTS OR SERVICES;
          AND, (2) ANY WARRANTIES OF TITLE, OF NON-INFRINGEMENT, OF MERCHANTABILITY, OF FITNESS FOR A PARTICULAR PURPOSE, OR
          WARRANTIES ARISING FROM A COURSE OF DEALING, TRADE USAGE, OR TRADE PRACTICE. THIS DISCLAIMER APPLIES TO ANY
          DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN
          OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS
          TO, ALTERATION OF, OR USE OF STRATOSPHERE’S RECORDS OR INFORMATION, WHETHER FOR BREACH OF CONTRACT, TORTIOUS
          BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.
        </p>

        <h3>Limitation of Liability</h3>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK. YOU ACKNOWLEDGE THAT INFORMATION
          TRANSMITTED THROUGH THE INTERNET IS NEVER COMPLETELY SECURE. NEITHER STRATOSPHERE NOR ANY OF ITS DIRECTORS,
          EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES, OR LICENSORS (COLLECTIVELY, “STRATOSPHERE ASSOCIATES”) SHALL BE
          LIABLE TO YOU OR ANY THIRD PARTY FOR ANY COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
          DAMAGES, OR ATTORNEYS’ FEES, ARISING OUT OF YOUR USE OF THE WEBSITE OR INABILITY TO GAIN ACCESS TO OR USE THE
          WEBSITE OR OUT OF ANY BREACH OF ANY WARRANTY, EVEN IF STRATOSPHERE OR THE STRATOSPHERE ASSOCIATE HAS BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE RIGHT AND REMEDY WITH RESPECT TO ANY DISPUTE WITH STRATOSPHERE IS TO
          STOP USING THE WEBSITE. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
          FOR CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES, IN SUCH STATES AND JURISDICTIONS LIABILITY IS LIMITED TO THE
          GREATEST EXTENT PERMITTED BY LAW.
        </p>

        <h3>Indemnification</h3>
        <p>
          You agree to defend, indemnify, and hold harmless Stratosphere, its agents and employees, and the Stratosphere
          Associates, from and against any claims, damages, costs, and expenses, including all costs of legal proceedings
          and attorneys’ fees, arising in connection with use of the Website by you or by anyone using Your Account, or in
          connection with a violation or breach of these Terms by you or by anyone using Your Account.
        </p>

        <h3>Notices</h3>
        <p>
          Where required, Stratosphere may give notice to you by a general posting on the Website, by electronic mail, or by
          conventional mail to your address of record. You may give notice to Stratosphere by electronic mail to the address
          below. If you have any questions about these Terms, the Website, or your dealings with Stratosphere, please email
          your questions to hr@stratospherequality.com.
        </p>

        <h3>General</h3>
        <p>
          The laws of the State of Indiana shall govern the validity, performance, enforcement, interpretation and any other
          aspect of these Terms, without regard to principles of conflicts of laws thereunder. The parties agree to submit
          to the exclusive jurisdiction and venue of the courts of Marion County, Indiana for any action arising out of
          these Terms.
        </p>

        <p>
          You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or
          related to use of the Website must be filed within one (1) year after such claim or cause of action accrues or be
          forever barred.
        </p>

        <p>
          You acknowledge and agree that the provisions, disclosures and disclaimers set forth in these Terms are fair and
          reasonable and your agreement to follow and be bound by them is not the result of fraud, duress or undue influence
          exercised upon you by any person or entity. The failure of Stratosphere to exercise or enforce any right or
          provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms
          is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should
          endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the
          Terms shall remain in full force and effect.
        </p>
        <p>
          Stratosphere shall have the right to assign these Terms and to sublicense any and all of its rights under these
          Terms. These Terms, including any documents referenced herein and any additional terms, conditions, or policies
          posted on the Website by Stratosphere from time to time, represent the entire understanding between you and
          Stratosphere regarding your relationship with Stratosphere and your use of the Website. These Terms supersede all
          previous written or oral agreements between you and Stratosphere with respect to such subject matter.
          Notwithstanding any provision of these Terms, Stratosphere has available all remedies at law or equity to enforce
          these Terms.
        </p>`,
    },
    privacyPolicy: {
      title: 'Privacy Policy',
      body: `<p>
          This Privacy Policy explains what types of personal information, also referred to as personal data, Stratosphere
          Quality, LLC may collect about Stratosphere and staffing contractor employees through the SQTeamwork.com website
          (the “Website”). We collect such personal information for coordinating with you about your work schedule,
          availability and work preferences. Stratosphere human resources may also use such information to improve
          communication and collaboration with you on other matters. In this privacy policy the terms “Stratosphere”, “we”,
          “us” and “our” refers to Stratosphere Quality, LLC and its affiliates.
        </p>

        <h3>What is Personal Information?</h3>

        <p>
          Personal information, also known as personally identifiable information or personal data means any information
          that either directly and clearly identifies an individual or can be used in conjunction with other information to
          identify an individual.
        </p>

        <h3>What Personal Information Do We Collect?</h3>

        <p>
          We collect and maintain different types of personal information about you in accordance with applicable law. Some
          examples include:
        </p>
        <ul>
          <li>Your name</li>
          <li>Your employee ID or payroll number</li>
          <li>Your home address</li>
          <li>Your home and/or mobile phone number</li>
          <li>Your personal email</li>
          <li>
            Information about your scheduling and work preferences including preferred week days and times of day, ability
            to travel and work overtime
          </li>
          <li>
            Communications with Stratosphere schedulers, human resources and operations management that are performed
            through use of the Website
          </li>
        </ul>

        <h3>How Is Personal Information Collected?</h3>

        <p>
          Generally, we collect personal information directly from you through forms and communications presented in the
          Website. However, in some instances such personal information may be provided by a human resources specialist, a
          supervisor, a scheduler or some other authorized Stratosphere representative through email or an alternative
          electronic method for the purpose of improving our ability to coordinate your work schedule and availability. We
          reserve the right to monitor your use of the Website and if such monitoring occurs then it may result in the
          collection of personal information about you.
        </p>
        <h3>How We Use the Personal Information We Collect?</h3>
        <p>Stratosphere may use your personal information to:</p>

        <ul>
          <li>Coordinate your work schedule and your work availability</li>
          <li>
            Provide you with information about your work history including where and when you recently performed your work
            for us
          </li>
          <li>Maintain directories of employees</li>
          <li>Send human resources engagement programs, including surveys</li>
          <li>Carry out other purposes as part of our business</li>
        </ul>

        <h3>Sharing Personal Information</h3>

        <p>
          Your personal information may be shared, including to our affiliates, subsidiaries and other third parties as
          follows:
        </p>
        <ul>
          <li>
            When using or collaborating with third parties in the operation of our business. When we share personal
            information with third parties we require that they only use or disclose such personal information as necessary
            to provide the requested service and to do so in a manner consistent with the provisions of this Privacy Policy
            and applicable law.
          </li>
          <li>
            To carry out the uses of personal information described in “How We Use the Personal Information We Collect.”
          </li>
          <li>
            If we, or substantially all our business assets are acquired, your personal information may be one of the
            transferred assets.
          </li>
          <li>
            When required by law or by order or requirement of a court or administrative agency including any lawful request
            by public authorities.
          </li>
          <li>To seek advice from our lawyers and other professional advisors.</li>
        </ul>

        <h3>Access to Personal Information We Collect</h3>
        <p>
          Most of the personal information collected about you through the Website is visible and available to you at the
          Website at any time. If you want to review, verify or correct related personal information that is not provided
          through the Website, you may submit a request via email to hr@stratospherequality.com. When requesting access to
          your personal information we may request specific information from you to allow us to confirm your identity and
          right to access such personal information. Your right to access the personal information is not absolute. There
          are instances where applicable law, or regulatory requirements or other Stratosphere policy might result in
          refusal to provide some or all of the personal information that we hold about you. We will inform you of the
          reason for such refusal subject to any legal or regulatory restrictions.
        </p>

        <h3>What Other Information Do We Collect and How Do We Use It?</h3>
        <p>
          We might collect site activity related information to help us provide a better user experience or feature
          experience to our users. Such information might include:
        </p>

        <ul>
          <li>
            Site activity such as which features of the site you use or visit or other links you click on and view and
            messages that you’ve sent.
          </li>
          <li>
            Information about your device and browser including type, version, IP address, crash reports, system activity
            and the date, time and referrer URL of your request.
          </li>
          <li>Your location information using IP address and GPS coordinates from GPS-enabled devices.</li>
          <li>
            Such usage and activity information will not be sold or shared to any third party for marketing or promotional
            activities and campaigns.
          </li>
        </ul>

        <h3>Security of Collected Information</h3>

        <p>
          We are committed to protecting the security of the personal information collected, and we take reasonable
          physical, electronic and administrative safeguards to help protect the information from unauthorized or
          inappropriate access or use. For example, all personal information collected is stored on electronic media with
          encryption enabled, meaning any third party gaining unauthorized access to the information will not be able to
          read it. In addition, home address information is restricted to system use only to determine distance from a home
          address to Stratosphere work site. This is used to select personnel for open assignments. Operations personnel do
          not have visibility to home addresses.
        </p>

        <h3>Resolving Concerns</h3>

        <p>
          If you have questions or concerns regarding the handling of your personal information, please contact
          hr@stratospherequality.com.
        </p>

        <h3>Changes to Privacy Policy</h3>

        <p>
          This Privacy Policy is reviewed periodically to ensure it accurately captures all types of data collected or any
          additional or different processing of such data. We reserve the right to change this Privacy Policy at any time
          and in our sole discretion.
        </p>`,
    },
    actions: {
      signIn: 'Sign In',
      signUp: 'Sign Up',
      signOut: 'Sign Out',
      askForSupport: 'Support',
    },
    allRightsReserved: 'All rights reserved',
  },
});
