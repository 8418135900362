import { RouteComponentProps } from '@reach/router';
import React, { FC, memo } from 'react';
import Routes from '../../Routes';
import Authorizer from './Authorizer';

const App: FC<RouteComponentProps> = () => {
  return (
    <div className='App'>
      <Authorizer>
        <Routes />
      </Authorizer>
    </div>
  );
};

export default memo(App);
