import React, { memo } from 'react';
import { Space, Switch, Input } from 'antd';
import { UserAvailability } from 'lib/entities/UserAvailabilities';

type ExtraInformationProps = {
  userAvailabilities: UserAvailability[];
  onChange: <K extends keyof UserAvailability>(
    key: K,
    value: UserAvailability[K]
  ) => void;
};

const ExtraInformation = ({
  userAvailabilities = [],
  onChange = () => {},
}: ExtraInformationProps) => {
  const { TextArea } = Input;

  const allDisabled = userAvailabilities.length === 0;

  return (
    <Space direction='vertical' size='small'>
      <h1>Information for the scheduler</h1>
      <p>
        Extra information that might help your scheduler to build a better
        schedule.
      </p>
      <Space>
        <Switch
          checked={allDisabled ? false : userAvailabilities[0].willTravel}
          aria-label='willTravel'
          disabled={allDisabled}
          onChange={(checked) => onChange('willTravel', checked)}
        />
        <span>I can reliably travel 30 miles</span>
      </Space>
      <Space>
        <Switch
          checked={allDisabled ? false : userAvailabilities[0].holidays}
          aria-label='holidays'
          disabled={allDisabled}
          onChange={(checked) => onChange('holidays', checked)}
        />
        <span>I can work holidays</span>
      </Space>
      <Space>
        <Switch
          checked={allDisabled ? false : userAvailabilities[0].overnightTravel}
          aria-label='overnightTravel'
          disabled={allDisabled}
          onChange={(checked) => onChange('overnightTravel', checked)}
        />
        <span>I can travel overnight</span>
      </Space>
      <Space>
        <Switch
          checked={allDisabled ? false : userAvailabilities[0].hasPassport}
          aria-label='hasPassport'
          disabled={allDisabled}
          onChange={(checked) => onChange('hasPassport', checked)}
        />
        <span>I have a passport</span>
      </Space>
      <TextArea
        value={allDisabled ? '' : userAvailabilities[0].note}
        disabled={allDisabled}
        onChange={({ target: { value } }) => onChange('note', value)}
        rows={4}
        placeholder='Notes for your scheduler (optional)'
      />
    </Space>
  );
};

export default memo(ExtraInformation);
