import geocoder from 'google-geocoder';

// A simple function to lowercase and remove spaces
const normalizeStr = (str) => {
  return str.toLowerCase().trim().replace(/ /gi, '');
};

export default function addressGeocoder(
  street_address,
  city,
  state,
  zip,
  country
) {
  return new Promise((resolve, reject) => {
    const addressString = `${street_address}, ${city}, ${state}, ${zip}, ${country}`;
    const splittedZip = zip.split('-');
    const zipWithSuffix = zip.length <= 0 ? false : splittedZip.length === 2;
    const geo = geocoder({
      key: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    geo.find(addressString, (error, results) => {
      if (error) {
        reject(new Error(`Error from Geocoding Api: ${error}`));
      } else {
        const match = results.find((address) => {
          if (address === undefined || address.city === undefined) {
            return false;
          } else {
            const cityMatchs =
              normalizeStr(address.city.long_name) === normalizeStr(city) ||
              normalizeStr(address.city.short_name) === normalizeStr(city);

            const stateMatchs =
              normalizeStr(address.administrative_area_level_1.long_name) ===
              normalizeStr(state);

            const countryMatchs =
              normalizeStr(address.country.long_name) === normalizeStr(country);

            let postalCodeMatchs = false;

            if (address.postal_code) {
              postalCodeMatchs =
                normalizeStr(address.postal_code.long_name) ===
                  normalizeStr(zip) ||
                normalizeStr(address.postal_code.short_name) ===
                  normalizeStr(zip);
              if (zipWithSuffix && address.postal_code_suffix) {
                postalCodeMatchs =
                  normalizeStr(splittedZip[1]) ===
                    normalizeStr(address.postal_code_suffix.long_name) ||
                  normalizeStr(splittedZip[1]) ===
                    normalizeStr(address.postal_code_suffix.short_name);
              }
            }

            return (
              cityMatchs && stateMatchs && countryMatchs && postalCodeMatchs
            );
          }
        });

        resolve({
          valid: !!match,
          latitude: match ? match.location.lat : 0,
          longitude: match ? match.location.lng : 0,
        });
      }
    });
  });
}
