import { define } from '@rlean/core';

export type ShiftType = {
  id: number;
  inactiveInd: boolean;
  modifiedAt: Date;
  shift: number;
  shiftName: string;
  weekday: number;
  weekdayName: string;
};

const ShiftTypes = define<ShiftType[]>('shiftTypes', {
  initialState: [],
  getURL: '/ShiftTypes',
});

export default ShiftTypes;
