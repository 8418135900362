import { define } from '@rlean/core';
type StateProvince = {
  code: string;
  countryId: number;
  id: number;
  name: string;
  inactiveInd: boolean;
  modifiedAt: Date;
};
const StateProvinces = define<StateProvince[]>('stateProvinces', {
  initialState: [],
  getURL: '/StateProvinces',
});

export default StateProvinces;
