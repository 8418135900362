import React, { memo } from 'react';
import { InputNumber, Modal, message, Form, Space, Button } from 'antd';
import { dayjs } from 'lib/helpers/dayjs';
import { usePost } from '@rlean/core';
import { uuid } from '@rlean/utils';
import { EmployeeShiftAssignmentMessages } from 'lib/entities';
import { strings } from 'config';
import { EmployeeShiftAssignmentMessagesType } from 'lib/entities/EmployeeShiftAssignmentMessages';
import { EmployeeShiftAssignment } from 'lib/entities/EmployeeShiftAssignments';

const SendToPhoneModal = ({
  schedule,
  visible = false,
  handleHideModal,
}: {
  schedule: EmployeeShiftAssignment;
  visible: boolean;
  handleHideModal: Function;
}) => {
  const [post] = usePost();

  const onSend = (values: any) => {
    message.destroy();

    const body = {
      body: '',
      employeeId: schedule.employeeId,
      employeeShiftAssignmentId: schedule.id,
      mobilePhone: (values.phone ?? '').toString(),
      guid: uuid(),
      scheduleDate: schedule.scheduleDate,
      shift: schedule.shift,
    };

    post<
      EmployeeShiftAssignmentMessagesType,
      EmployeeShiftAssignmentMessagesType,
      typeof EmployeeShiftAssignmentMessages
    >({ entity: EmployeeShiftAssignmentMessages, body }, (_, error) => {
      if (error) {
        message.error(`Couldn't send info. Please, try again.`);
      } else {
        message.success('Message sent!');
        handleHideModal();
      }
    });
  };

  if (!schedule) {
    return null;
  }

  return (
    <Modal
      title={`Send schedule info to your phone`}
      visible={visible}
      footer={null}
      onCancel={() => handleHideModal()}
    >
      <p>
        Schedule for {schedule.workSiteName} on{' '}
        {dayjs(schedule.scheduleDate).format('MM/DD/YYYY')}.
      </p>
      <p>{strings.mySchedule.modalDescription}:</p>
      <Form onFinish={onSend}>
        <Form.Item
          name='phone'
          rules={[
            { type: 'number', message: 'The phone should be only numbers' },
            { required: true, message: 'This field is required' },
            {
              transform: (number) => number.toString(),
              len: 10,
              message: 'The phone should have 10 digits',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} placeholder='Phone number' />
        </Form.Item>
        <Space>
          <Form.Item>
            <Button type='default' onClick={() => handleHideModal()}>
              {strings.mySchedule.action.cancel}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              {strings.mySchedule.action.send}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default memo(SendToPhoneModal);
