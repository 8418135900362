import React from 'react';
import { useGet, useGlobalState } from '@rlean/core';
import { hasValue, getValue } from '@rlean/utils';
import { UserDescription } from 'lib/entities';
import Unauthorized from 'components/pages/Unauthorized';
import { navigate } from '@reach/router';
import { pages } from 'config';
import * as entities from 'lib/entities';
import LoadingSpin from 'components/shared/LoadingSpin';
import { msalInstance } from 'config/msal';

/**
 * Handle the authorization of the app.
 *
 * The authorization works by trying to get the UserDescription model, if this
 * returns a 403 code, then it means that the user is indeed unauthorized.
 */
const Authorizer = ({ children }: any) => {
  const account = msalInstance.getActiveAccount();
  const { oid } = account!.idTokenClaims!;

  const [{ userDescription }] = useGlobalState<typeof entities>();

  useGet({ entity: UserDescription, params: { oid } });

  if (userDescription && userDescription.isLoading) {
    return <LoadingSpin />;
  }

  if (hasValue(userDescription, 'error')) {
    if (getValue(userDescription, 'error.response.status') === 403) {
      return <Unauthorized />;
    } else {
      navigate(pages.UNEXPECTED_ERROR.path);
    }
  }

  return children;
};

export default Authorizer;
