import React, { memo, useState } from 'react';
import { pages } from 'config';
import { useActivePage } from 'lib/hooks';
import {
  Badge,
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Select,
  Space,
} from 'antd';
import { ContactUs, ContactUsRequestTypes } from 'lib/entities';
import { useGet, usePost, useGlobalState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { isAnyStateLoading } from 'lib/helpers/stateDataChecks';
import { PhoneOutlined } from '@ant-design/icons';
import { RouteComponentProps } from '@reach/router';
import * as entities from 'lib/entities';
import { ContactUsType } from 'lib/entities/ContactUs';

const SUPPORT_STATUS = {
  IDLE: 'IDLE',
  SENDING: 'SENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const Support = (props: RouteComponentProps) => {
  const [status, setStatus] = useState(SUPPORT_STATUS.IDLE);
  const [{ userDescription, contactUsRequestTypes }] =
    useGlobalState<typeof entities>();
  const [form] = Form.useForm();
  const [post] = usePost();

  const userObjectId: number = getValue(userDescription, 'data.objectId');

  useActivePage(pages.SUPPORT);
  useGet({ entity: ContactUsRequestTypes });

  const sendFeedback = (values: ContactUsType) => {
    message.destroy();
    message.loading('Sending feedback...');
    setStatus(SUPPORT_STATUS.IDLE);
    post(
      { entity: ContactUs, body: { ...values, userObjectId } },
      (_, error) => {
        message.destroy();
        if (error) {
          setStatus(SUPPORT_STATUS.ERROR);
          message.error(
            'There has been an error sending your feedback. Try again later.'
          );
        } else {
          message.success('Feedback sent!');
        }
        setStatus(SUPPORT_STATUS.SUCCESS);
        form.resetFields();
        setTimeout(() => {
          setStatus(SUPPORT_STATUS.IDLE);
        }, 4000);
      }
    );
  };

  const requestTypesLoading = isAnyStateLoading(contactUsRequestTypes);

  return (
    <Card className='page-card' title={pages.SUPPORT.title}>
      <h2>Send us feedback</h2>
      <Form<ContactUsType>
        form={form}
        layout='vertical'
        onFinish={sendFeedback}
      >
        <Form.Item
          name='requestTypeId'
          label='What kind of comment would you like to make?'
          rules={[{ required: true }]}
        >
          <Select>
            {requestTypesLoading
              ? null
              : contactUsRequestTypes.data.map((requestType) => (
                  <Select.Option
                    key={`request-type-${requestType.id}`}
                    value={requestType.id}
                  >
                    {requestType.name}
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
        <Form.Item name='message' label='Comment' rules={[{ required: true }]}>
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              type='primary'
              htmlType='submit'
              disabled={
                requestTypesLoading || status === SUPPORT_STATUS.SENDING
              }
            >
              Send Feedback
            </Button>
            {status === SUPPORT_STATUS.SUCCESS ? (
              <Badge status='success' text='Feedback sent!' />
            ) : null}
          </Space>
        </Form.Item>
      </Form>
      <Divider />
      <h2>Support Contact Numbers</h2>
      <h3>Human Resources or Benefits</h3>
      <a href='tel:+1-877-244-8584'>
        <PhoneOutlined />
        877.244.8584 *Dial 2
      </a>
      <h3>Payroll</h3>
      <a href='tel:+1-877-244-8584'>
        <PhoneOutlined />
        877.244.8584 Ext. 587
      </a>
      <h3>IT Help Desk</h3>
      <a href='tel:+1-317-288-3116'>
        <PhoneOutlined />
        317.288.3116
      </a>
    </Card>
  );
};

export default memo(Support);
