import { define } from '@rlean/core';

export type UserDescriptionType = {
  appRoles: string[];
  displayName: string;
  email: string;
  employeeId: string;
  isMobileUser: boolean;
  mobileUserId: number;
  objectId: string;
};

const UserDescription = define<UserDescriptionType>('userDescription', {
  initialState: {},
  getURL: '/UserDescriptions/:oid',
});

export default UserDescription;
