import { define } from '@rlean/core';

export type UserShiftType = {
  employeeId: string;
  id: number;
  inactiveInd: boolean;
  modifiedAt: string; // Date
  modifiedByEmployeeId: string;
  shiftTypeId: number;
};

const UserShiftTypes = define<UserShiftType[]>('userShiftTypes', {
  initialState: [],
  nullableParams: false,
  getURL: '/EmployeeShiftTypes',
  postURL: '/EmployeeShiftTypes',
});

export default UserShiftTypes;
