import React, { memo } from 'react';
import { Card } from 'antd';
import { navigate, RouteComponentProps } from '@reach/router';
import { useActivePage } from 'lib/hooks';
import { pages } from 'config/pages';

const UnexpectedError = (props: RouteComponentProps) => {
  useActivePage(pages.UNEXPECTED_ERROR);

  const tryAgain = () => {
    navigate(pages.HOME.path);
  };

  return (
    <Card className='page-card' title={pages.UNEXPECTED_ERROR.title}>
      An unexpected error has occurred. Please contact our help desk at{' '}
      <a href='mailto:itrequests@stratospherequality.com'>
        itrequests@stratospherequality.com
      </a>{' '}
      for assistance or{' '}
      <span className='link-button' onClick={() => tryAgain()}>
        try your request again
      </span>
      .
    </Card>
  );
};

export default memo(UnexpectedError);
