import { define } from '@rlean/core';
import { Page } from 'config/pages';

// export type ActivePageType = {
//   title?: string;
//   pages?: string;
//   key?: string;
// };

const ActivePage = define<Page>('activePage', {
  initialState: {},
});

export default ActivePage;
